















































































import './scss/BaseInput.scss';
import './scss/BaseInputAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate } from '@utility/formatDate';

@Component({
    name: 'BaseInput',
})
export default class BaseInput extends Vue {
    $refs!: {
        input: HTMLFormElement,
    }

    @Prop({ default: '' }) value!: string;
    @Prop({ default: 'text' }) type!: string;
    @Prop({ default: () => ({}) }) inputAttributes!: any;
    @Prop({ default: '' }) label!: string;
    @Prop({ default: '' }) placeholder!: string;
    @Prop({ default: '' }) required!: string;
    @Prop({ default: '' }) caption!: string;
    @Prop({ default: '' }) inputClass!: string;
    @Prop({ default: false }) error!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: false }) hideBorder!: boolean;

    showPassword: boolean = false;

    emitInput(value: string) {
        this.$emit('input', value);
    }

    get formattedInputAttributes() {
        if (this.inputAttributes) {
            if ('min' in this.inputAttributes) {
                return {
                    ...this.inputAttributes,
                    min: this.inputAttributes.min.replace('T00:00:00', ''),
                };
            }
            if ('max' in this.inputAttributes) {
                return {
                    ...this.inputAttributes,
                    max: this.inputAttributes.max.replace('T00:00:00', ''),
                };
            }
            if ('min' in this.inputAttributes && 'max' in this.inputAttributes) {
                return {
                    ...this.inputAttributes,
                    min: this.inputAttributes.min.replace('T00:00:00', ''),
                    max: this.inputAttributes.max.replace('T00:00:00', ''),
                };
            }
        }
        return this.inputAttributes;
    }

    formattedDate(date) {
        return formatDate(date, 'yyyy-mm-dd');
    }

    // yyyy-MM-dd
    formatDate(date: Date) {
        let formattedMonth = String(date.getMonth() + 1);
        formattedMonth = +formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth;
        let day: string | number = date.getDate();
        if (day < 10) day = `0${day}`;
        return `${date.getFullYear()}-${formattedMonth}-${day}`;
    }

    focusInput() {
        this.$refs.input.focus();
    }

    setFieldValue(val) {
        this.$refs.input.value = val;
    }
}
