var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"base-input"},[(_vm.label)?_c('span',{staticClass:"base-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"base-input__wrapper"},[_c('input',_vm._b({ref:"input",class:[
            'base-input__input',
            { 'base-input__input--error': _vm.error },
            { 'base-input__input--hide-border': _vm.hideBorder },
            { 'base-input__input--number': _vm.type === 'number' },
            { 'base-input__input--readonly': _vm.readonly },
            { 'base-input__input--password': _vm.type === 'password' },
            _vm.inputClass ],attrs:{"type":_vm.type === 'password' ? _vm.showPassword ? 'type' : 'password' : _vm.type,"placeholder":_vm.placeholder,"readonly":_vm.readonly ? _vm.readonly : false},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.emitInput($event.target.value)}}},'input',_vm.formattedInputAttributes,false)),(_vm.type === 'password')?_c('button',{staticClass:"base-input__eye-btn",attrs:{"type":"button","title":_vm.showPassword ? 'Скрыть пароль' : 'Показать пароль'},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassword),expression:"showPassword"}],staticClass:"base-input__eye-btn-icon",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#icon-eye-close"}})]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassword),expression:"!showPassword"}],staticClass:"base-input__eye-btn-icon",attrs:{"width":"18","height":"18"}},[_c('use',{attrs:{"xlink:href":"#icon-eye-open"}})])]):_vm._e()]),(_vm.required || _vm.caption)?_c('span',{staticClass:"base-input__caption"},[_c('svg',{staticClass:"base-input__caption-icon",attrs:{"width":"12","height":"12"}},[_c('use',{attrs:{"xlink:href":"#icon-alert"}})]),(_vm.required)?_c('p',{staticClass:"base-input__caption-require-alert"},[_vm._v(" Обязательно для заполнения ")]):_vm._e(),(_vm.caption)?_c('p',{staticClass:"base-input__caption-text"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }